<template>
	<div id="goods_box">
		<div class="pic position-relative">
			<img :src="pic" alt="" class="position-relative" style="z-index: 2">
			<img class="position-absolute w-100"  :src="cover" alt="">
		</div>
	</div>
</template>

<script>
	export default{
		name:'goods_box',
		props:{
			pic:{
				type:String
			},
			cover:{
				type:String
			}
		},

		filters:{

		},
		data(){
			return{
				Wpic:''
			}
		},

		watch:{
			pic(v){
				// this.Wpic = localStorage.getItem('static_file_url') + v
			}
		}
	}
</script>

<style lang="scss">
	#goods_box{
		width: 8rem;
		height:2rem;
		background: url(../../../assets/images/OpenBox/openbox.png) no-repeat center;
		background-size: 100% 100%;
		margin: 0.2rem auto;
		.pic{
			width: 2.5rem;
			height: 100%;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;
			img{
				max-width: 90%;
				max-height: 90%;
				vertical-align: middle;
			}
		}
	}
</style>
