<template>
	<div id="Goods">
		<div class="Goods_box">
			<div class="Title Title-bg">
				<p :class="{'active' : Switch == '2'}" @click="Switch = '2'">箱内物品</p>
			</div>
			<div class="Goods_list" >
				<div class="Goods_item" v-for="(item,index) in DetailList" :key="index"
				>
					<div class="odds_percent">
						<div class="d-flex align-items-center">
							<img style="width: 18px" src="@/assets/images/public/Gold.png" alt="">
							{{item.bean}}</div>
					</div>
					<div class="dura_alias">概率:{{item.odds_percent}}</div>

					<div class="Goods_pic" :style="{'background-image': 'url('+item.lv_bg_image+')'}">
						<img :src="item.cover" alt="">
					</div>
					<p>{{item.name}}</p>
					<div class="" style="font-size: 12px;">{{item.dura_alias== '无'?'':item.dura_alias}}</div>
				</div>
			</div>


			<div class="Title Title-bg">
				<p :class="{'active' : Switch == '1'}" @click="Switch = '1'">最近掉落</p>
			</div>
			<div class="history_list" >
				<div v-for="(item, index) in historylist" :key="index">
					<div class="history_item" >
						<div class="ceng" v-if="item.user">
							<div class="C_pic">
								<img :src="item.user.avatar ? item.user.avatar : ''" alt="">
							</div>
							<!-- <p>{{ item.user.name }}</p> -->
						</div>
						<div class="history_pic" :style="{ 'background-image': 'url(' + item.lv_bg_image + ')' }">
							<img :src="item.cover" alt="">
						</div>
						<p>{{ item.name }}</p>
						<p class="d-flex align-items-center justify-content-center"><img style="width: 18px" src="@/assets/images/public/Gold.png" alt="">{{ item.bean }}</p>
					</div>
				</div>
			</div>



		</div>
	</div>
</template>

<script>
	import {
		UnpackingRecord
	} from '@/network/api.js'
	export default {
		name: 'Goods',
		props: {
			DetailList: { //箱内物品列表
				type: Array
			},
			id: { //宝箱id
				type: String
			}
		},
		data() {
			return {
				Switch: '2', //掉落/物品
				historylist: [], //历史掉落列表
				timer:null
			}
		},
		activated() {
			this.GetUnpackingRecord()
			this.SetUnpackingRecord()

		},
		methods: {
			//历史掉落
			GetUnpackingRecord() {
				UnpackingRecord(this.id).then((res) => {
					this.historylist = res.data.data.historylist
				})
			},
			SetUnpackingRecord() {
				setInterval(() => {
					this.GetUnpackingRecord()
				}, 20000)
			},
		},

		watch: {
			id(v) {
				console.log(v)
				this.GetUnpackingRecord()
			},
			DetailList(v) {
				// console.log(this.DetailList)
			}
		}
	}
</script>

<style lang="scss">
	#Goods {
		padding: 10px;
		box-sizing: border-box;
		margin-top: 40px;

		.Goods_box {
			width: 100%;

			.Goods_nav {
				width: 100%;
				display: flex;
				color: #fff;

				p {
					width: 120px;
					height: 40px;
					background: #fff;
					color: #000;
					text-align: center;
					line-height: 40px;
					font-size: 16px;
					cursor: pointer;

					&.active {
						background: #ffba2e;
					}
				}
			}

			.history_list {
				width: 100%;
				display: flex;
				padding: 5px 0;
				// flex-wrap: wrap;
				border-radius: 0 4px 4px 4px;
				margin-top: 20px;
				overflow-x: hidden;
				overflow-y: hidden;

				.history_item {
					width: .8rem;
					text-align: center;
					font-size: 12px!important;
					color: #fff;
					margin-bottom: 10px;
					margin-right: 5px;
					padding-bottom: 20px;
					background-color: rgba($color: #000000, $alpha: .2);
					/*background: url(../../assets/images/public/back_pub.jpg) no-repeat center;*/
					background-size: cover;
					// border-radius: 6px;
					position: relative;
					cursor: pointer;

					.ceng {
						position: absolute;
						width: 50px;
						height: 50px;
						text-align: center;
						transition: .3s;
						right: -15px;



						.C_pic {
							width: 30px;
							height: 30px;
							border-radius: 50%;
							overflow: hidden;
							margin: 40px auto 10px;

							img {
								width: 100%;
								height: 100%;
							}

							p {
								font-size: 12px;
								color: #fff;
							}
						}
					}

					// &:hover {
					// 	.ceng {
					// 		top: 0
					// 	}
					// }

					.history_pic {
						width: 100%;
						height: 100px;
						margin: 0 auto;
						display: flex;
						justify-content: center;
						align-items: center;

						background-image: url(http://csgo.api.gameskins.cn/uploads/images/baeef9d5696b72118858f87ff955bee7.png);
						background-position: center;
						background-size: 100%;
						background-repeat: no-repeat;

						img {
							max-width: 100%;
							vertical-align: middle;
						}
					}

					p {
						width: 100%;
						padding: 0 3px;
						box-sizing: border-box;
						font-size: 14px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}

			.Goods_list {
				width: 100%;
				display: flex;
				padding: 5px 0;
				flex-wrap: wrap;
				border-radius: 0 4px 4px 4px;
				margin-top: 20px;

				.Goods_item {
					width: 1.8rem;
					text-align: center;
					color: #fff;
					margin-bottom: 10px;
					margin-right: 10px;
					padding-bottom: 10px;
					background-color: rgba($color: #000000, $alpha: .2);
					/*background: url(../../assets/images/public/back_pub.jpg) no-repeat center;*/
					background-size: cover;
					// border-radius: 6px;
					position: relative;
					overflow: hidden;
					cursor: pointer;
					position: relative;

					.odds_percent {
						position: absolute;
						left: 0;
						top: 0;
						font-size: 12px;
						margin: 5px 0;
						text-align: left;
						padding-left: 10px;
						box-sizing: border-box;
						color: #fff;
					}

					.dura_alias {
						position: absolute;
						right: 0;
						top: 0;
						font-size: 10px;
						margin: 5px 0;
						text-align: left;
						padding-right: 10px;
						box-sizing: border-box;
						color: #fff;
					}

					.Goods_pic {
						width: 80%;
						height: 140px;
						margin: 10px auto 0;
						display: flex;
						justify-content: center;
						align-items: center;
						background-position: center;
						background-size: 100%;
						background-repeat: no-repeat;

						img {
							max-width: 100%;
							vertical-align: middle;
						}
					}

					p {
						width: 100%;
						padding: 0 3px;
						box-sizing: border-box;
						font-size: 14px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						margin: -10px 0 10px;
					}

					span {
						display: flex;
						justify-content: center;
						align-items: center;
						color: #75cd66;
						font-size: 16px;
						margin: 5px 0 0;

						img {
							width: 20px;
						}
					}
				}
			}
		}
	}
</style>
